











































import {Vue, Component, Prop, Watch} from "vue-property-decorator";
import AboutItemType from "@/views/Friend/MyFriend/components/AboutItem/indexType";
import { MyAboutItemType } from "@/views/Friend/MyFriend/MyFriend";

@Component({name: "AboutItem"})
export default class AboutItem extends Vue implements AboutItemType{
    giveIcon = require("../../../Img/FirendGive.png")

    handleToPathSuc(){
        this.$router.push({
            name:"friendSuccess",
            query:{
                id:this.getData.momentsId + '',
                state:'myAbout'
            },
        })
    }

    get getPhoto(){
        return this.getData && this.getData.headImg || require("@/assets/icon/Basics/default.png")
    }

    get giveBool(){
        if ( this.getData && Number( this.getData.type ) === 2 ){
            return true
        }else{
            return false
        }
    }

    get giveCommentBool(){
        if ( this.getData && Number( this.getData.type ) === 6 ){
            return true
        }else{
            return false
        }
    }

    get commentBool(){
        if ( this.getData && Number( this.getData.type ) === 4 ){
            return true
        }else{
            return false
        }
    }

    get contentImgShow(){
        if ( this.getData && this.getData["toHeadImg"]  && this.getData["toHeadImg"] .length ){
            return true
        }else{
            return false
        }
    }

    @Prop(Object)
    data!:MyAboutItemType
    get getData(){ return this.data }
}
