























import {Vue, Component, Prop, Watch} from "vue-property-decorator";
import MyFriendListViewType from "@/views/Friend/MyFriend/components/ListView/indexType";
import PullDownUpList from "@/components/ShareComponent/PullDownUp.vue"
import ListBottom from "@/components/ShareComponent/ListBottom.vue";
import FriendComponents from "@/views/Friend/components/FriendComponents/index.vue";
import AboutItem from "@/views/Friend/MyFriend/components/AboutItem/index.vue";
import { testData } from "@/views/Friend/MyFriend/Model";
import { ItemType } from "@/views/Friend/FriendCircle/Store/indexType";
import {getMyAbouts, getMyFriends} from "@/views/Friend/MyFriend/Server";
import {MyFriendStore} from "@/views/Friend/MyFriend/Store";
import {MyAboutItemType} from "@/views/Friend/MyFriend/MyFriend";


@Component({name: "MyFriendListView",components:{ PullDownUpList,ListBottom,FriendComponents,AboutItem }})
export default class MyFriendListView extends Vue implements MyFriendListViewType{
    PullDown = true
    UpDownBool = true
    StopUp = false
    List: ItemType[] = testData;
    AboutList:MyAboutItemType[] = []

    mounted(){
        this.List = this.getList
        this.AboutList = this.getAboutList
        this.handleUpData()
    }

    handlePullDown(): void {
        this.PullDown = true
        this.StopUp = false
        MyFriendStore.setMyFriendsUpData( { ...MyFriendStore.getMyFriendsUpData,pageNo:1 } )
        this.handleUpData( true )
    }
    handleUpDown(): void {
        if (this.StopUp) return;
        let data = MyFriendStore.getMyFriendsUpData
        data.pageNo += 1
        MyFriendStore.setMyFriendsUpData( data )
        this.handleUpData()
    }
    handleUpData(bool=false): void {
        if (MyFriendStore.getMyFriendsNavIndex) {
            getMyAbouts().then(res=>{
                this.PullDown = false
                this.StopUp = true
                this.UpDownBool = false
                MyFriendStore.setMyFriendsAboutList( res )
            })
        }else{
            getMyFriends().then(res=>{
                this.PullDown = false
                this.UpDownBool = false
                if ( res.length < MyFriendStore.getMyFriendsUpData.pageSize )this.StopUp = true;
                if ( bool )
                    MyFriendStore.setMyFriendsList( res );
                else
                    MyFriendStore.setMyFriendsList( MyFriendStore.getMyFriendsList.concat(res) );
            })
        }
    }

    @Prop(Number)
    state!:number
    get getState(){ return this.state }
    @Watch("getState")
    changeState(newVal:number){
        this.StopUp = false
        this.UpDownBool = true
        this.handleUpData(true)
    }

    get getList(){
        return MyFriendStore.getMyFriendsList
    }
    @Watch("getList",{ deep:true })
    changeList(newVal:ItemType[]){
        this.List = newVal
    }

    get getAboutList(){
        return MyFriendStore.getMyFriendsAboutList
    }
    @Watch("getAboutList",{ deep:true })
    changeAboutList(newVal:MyAboutItemType[]){
        this.AboutList = newVal
    }
}
