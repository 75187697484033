


































import {Vue, Component, Watch} from "vue-property-decorator";
import MyFriendType from "@/views/Friend/MyFriend/MyFriend";
import MyFriendListView from "@/views/Friend/MyFriend/components/ListView/index.vue";
import HeadTop from "@/components/ShareComponent/HeadTop.vue"
import {navData} from "@/views/Friend/MyFriend/Model/index";
import {MyFriendStore} from "@/views/Friend/MyFriend/Store";
import ZoomPage from "@/impView/PageSafety";
import {getMyAboutUnread} from "@/views/Friend/Server";

@Component({name: "MyFriend",components:{ MyFriendListView,HeadTop }})
export default class MyFriend extends ZoomPage implements MyFriendType{
    navList = navData
    navIndex = 0
    loadingShow = true;
    unreadCount = 0

    mounted(){
        this.loadingShow = false
        this.navIndex = MyFriendStore.getMyFriendsNavIndex
        this.handleUpData()
    }

    handleBackClick(){
        //
    }

    handleUpData(){
        getMyAboutUnread().then(res=>{
            this.unreadCount = res["count"]
        })
    }

    // handleChangeNav(index:number){
    //     // if ( this.navIndex != index )
    //     //     this.navIndex = index;
    //     console.log( index )
    //     if ( MyFriendStore.getMyFriendsNavIndex != index ){
    //         this.navIndex = index;
    //         MyFriendStore.setMyFriendsNavIndex(index);
    //     }
    // }

    @Watch("navIndex")
    changeNavIndex(newVal:number){
        if ( MyFriendStore.getMyFriendsNavIndex != newVal ){
            MyFriendStore.setMyFriendsNavIndex(newVal);
            this.handleUpData()
        }
    }
}
