

export const navData = [
    "我的发布",
    "关于我的",
]


export const testData = [
    {
        address: "郑州市 郑州大学医学院出版社",
        commentsCount: 0,
        content: "道生一 一生二 二生三 三生万物",
        createTime: "2021-05-17T15:49:20.000+0800",
        createTimeStr: "2021-05-17 15:49",
        forwardCount: 0,
        headImg: "https://thirdwx.qlogo.cn/mmopen/vi_32/Rfib9LRPE5II9mqevR88LYmBqgEKpyOrl3d15tRQiaPmojmTLekHfYzl2baiaXj3hpoUyLaguQahLsDmTd8B5bqSA/132",
        id: 120,
        image: "http://sy-campus.oss-cn-hangzhou.aliyuncs.com/friend/2021/05/17/9528fbda090c45d5affcc615ae3eb9ca-1795a49d024.jpg",
        isFriend: 0,
        isLike: 1,
        likeCount: 1,
        pageNo: null,
        pageSize: null,
        queryAuth: 0,
        releaseTimeStr: "2021-05-17 15:49",
        remark: null,
        school: "中原工学院（南校区）",
        sex: "1",
        status: 0,
        updateTime: "2021-05-17T15:49:20.000+0800",
        userId: "2",
        userName: "聆听🍃",
        weigth: 0,
    },
    {
        address: "郑州市 中原工学院科技园",
        commentsCount: 0,
        content: "咳咳",
        createTime: "2020-09-08T11:20:45.000+0800",
        createTimeStr: "2020-09-08 11:20",
        forwardCount: 0,
        headImg: "https://thirdwx.qlogo.cn/mmopen/vi_32/Rfib9LRPE5II9mqevR88LYmBqgEKpyOrl3d15tRQiaPmojmTLekHfYzl2baiaXj3hpoUyLaguQahLsDmTd8B5bqSA/132",
        id: 117,
        image: "http://sy-campus.oss-cn-hangzhou.aliyuncs.com/friend/2020/09/08/b3dbbc7dcc2640ccba70770283472dba-1742fb8b374.jpg",
        isFriend: 0,
        isLike: 0,
        likeCount: 2,
        pageNo: null,
        pageSize: null,
        queryAuth: 0,
        releaseTimeStr: "2020-09-08 11:20",
        remark: null,
        school: "中原工学院（南校区）",
        sex: "1",
        status: 0,
        updateTime: "2020-09-08T11:20:45.000+0800",
        userId: "2",
        userName: "聆听🍃",
        weigth: 0,
    }
]
